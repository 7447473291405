<template>
  <div class="page">
    <div class="dkzc-box" v-if="detail">
      <div class="left">
        <!-- 标题 -->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/found' }">发现</el-breadcrumb-item>
          <el-breadcrumb-item>正文</el-breadcrumb-item>
        </el-breadcrumb>
        <onshare></onshare>
        <!-- 个人信息 -->
        <div class="personage">
          <img :src="imgUrl" style="cursor: pointer" @click="goPersonal" />
          <div class="personage-right">
            <div class="one">
              <span class="title">{{ detail.info.doctor.name }}</span>
              <span class="position">{{ detail.info.doctor.title_name }}</span>
              <template v-if="detail.info.doctor.user_id != userInfo.id">
                <span
                  v-if="detail.info.attention"
                  @click="postDiscoverAttention"
                  class="button2"
                  >已关注</span
                >
                <span @click="postDiscoverAttention" v-else class="button"
                  >+关注</span
                >
              </template>
            </div>
            <div class="two">
              <span class="site">{{ detail.info.doctor.company }}</span>
              <span>{{ detail.info.doctor.department_name }}</span>
            </div>
          </div>
        </div>
        <span class="leftTitle">{{ detail.info.title }}</span>
        <!-- 视频 -->
        <video
          ref="videoPlayer"
          controls
          :src="detail.info.video"
          :poster="detail.info.video_img"
          controlslist="nodownload"
          style="width: 100%; height: 300px"
        ></video>
        <!-- 简介正文 -->
        <div class="introduction">
          <span class="subTitle" v-html="detail.info.content"></span>
          <div class="introductionTime">
            <span class="timeName">{{ detail.info.doctor.name }}</span>
            <span>{{ detail.info.create_time | formaTime }}</span>
          </div>
        </div>
        <!-- 点赞收藏图标区 -->
        <div class="icons">
          <div class="favoritesNumber">
            <i
              @click="favoritesNumber"
              class="iconfont shoucang"
              :class="{ shoucanghover: detail.info.collect_status }"
            ></i>
            <span>{{ detail.info.collect_num || 0 }}</span>
          </div>
          <div class="like">
            <i
              @click="articlePraiseFn"
              class="iconfont zan1"
              :class="{ iconzan2: !detail.info.praise_status }"
            ></i>
            <span>{{ detail.info.praise_num || 0 }}</span>
          </div>

          <div
            v-if="detail.info.user_id != userInfo.id"
            class="report"
            @click="chengReport(id, 1, detail.info.user_id)"
          >
            <img src="@image/report.png" alt="" />
            举报
          </div>

          <span class="share">分享：</span>
          <share :config="config"></share>
        </div>
        <!-- 评论数 -->
        <!-- <div class="numberComments">
          <span class="number">{{ detail.comment.length }}</span>
          <span>条 评论</span>
        </div> -->
        <commentNum
          :commentNumber="detail.comment.length"
          :likeCount="detail.likeCount"
          :shareCount="detail.sharecount"
          :id="id"
        ></commentNum>
        <comment
          width="840"
          @refresh="findDetail"
          :discover_id="detail.info.id"
          :comments="detail.comment"
          @delete_comment="delete_comment"
        >
        </comment>
      </div>
      <!-- 右侧信息区 -->
      <div class="right-group" v-if="detail.recommend.length">
        <div
          class="group"
          v-for="(item, index) in detail.recommend"
          :key="index"
          @click="goDetail(item)"
        >
          <div class="group-left">
            <!-- <span class="Play">12:11</span> -->
            <img
              class="information"
              v-if="item.video_img || item.images.length"
              :src="item.video_img || item.images[0]"
            />
            <!-- <img v-else class="information" src="@image/QR-Code.png" /> -->
          </div>
          <div class="right">
            <span class="title">{{ item.title }}</span>
            <div class="site">
              <div v-html="item.content"></div>
            </div>
            <div class="time">
              <span>{{ item.browse_num }}次观看 </span>
            </div>
          </div>
        </div>
      </div>
      <div class="right-group" v-else>
        <div class="null-box">
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import comment from "@components/common/comment";
import commentNum from "@components/common/commentNum";
import onshare from "@components/share/onShare";

import { mapState } from "vuex";
export default {
  components: {
    comment,
    commentNum,
    onshare,
  },
  data() {
    return {
      detail: "",
      request: true,
      config: {
        url: window.location.href,
        sites: ["wechat", "qq", "weibo"],
        wechatQrcodeTitle: "微信扫一扫：分享",
        wechatQrcodeHelper:
          "<p>扫一扫</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
    };
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
    // 默认图片
    imgUrl() {
      return this.detail.info.doctor.photo
        ? this.detail.info.doctor.photo
        : require("@image/default.png");
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.findDetail();
  },
  methods: {
    goPersonal() {
      let url = "/found-personal?id=" + this.detail.info.doctor.user_id;
      this.xin.goUrl(url);
    },

    goDetail(item) {
      if (item.type == 3) {
        this.xin.goUrl("/found-details-videoDetails?id=" + item.id);
      } else {
        this.xin.goUrl("/found-details-details?id=" + item.id);
      }
    },
    // 发现详情
    async findDetail() {
      this.xin.isLoadding(true);
      let res = await this.$findApi.findDetail({
        discover_id: this.id,
      });
      if (res.code == 200) {
        let data = res.data || [];
        if (data.info && data.info.images) {
          data.info.images = JSON.parse(data.info.images);
        } else {
          data.info.images = [];
        }

        if (data.recommend.length) {
          for (let i in data.recommend) {
            data.recommend[i].images
              ? (data.recommend[i].images = JSON.parse(
                  data.recommend[i].images
                ))
              : (data.recommend[i].images = []);
          }
        }
        this.detail = data;
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
      this.xin.isLoadding();
    },
    // 关注
    async postDiscoverAttention() {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }

      let item = this.detail.info.doctor;
      // 防止多次发送请求
      if (this.request) {
        this.request = false;
        let res = await this.$userApi.postDiscoverAttention({
          attention_id: item.user_id,
        });
        this.request = true;
        if (res.code == 200) {
          if (this.detail.info.attention) {
            this.$message({
              message: "已取消关注",
              type: "success",
            });
            this.detail.info.attention = false;
          } else {
            this.$message({
              message: "关注成功",
              type: "success",
            });
            this.detail.info.attention = true;
          }
        }
      }
    },
    // 收藏
    async favoritesNumber() {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }

      // 防止多次发送请求
      if (this.request) {
        this.request = false;
        let res = await this.$findApi.findCollection({
          discover_id: this.detail.info.id,
        });
        this.request = true;
        if (res.code == 200) {
          if (this.detail.info.collect_status) {
            this.detail.info.collect_num -= 1;
            this.$message({
              message: "已取消收藏",
              type: "success",
            });
          } else {
            this.detail.info.collect_num += 1;
            this.$message({
              message: "收藏成功",
              type: "success",
            });
          }
          this.detail.info.collect_status = !this.detail.info.collect_status;
        }
      }
    },
    // 文章点赞
    async articlePraiseFn() {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }

      // 防止多次发送请求
      if (this.request) {
        this.request = false;
        let res = await this.$findApi.findCommentLike({
          like_id: this.$route.query.id,
          type: 1,
        });
        this.request = true;
        if (res.code == 200) {
          if (this.detail.info.praise_status) {
            this.detail.info.praise_num -= 1;
            this.$message({
              message: "已取消点赞",
              type: "success",
            });
            this.findDetail();
          } else {
            this.detail.info.praise_num += 1;
            this.$message({
              message: "点赞成功",
              type: "success",
            });
            this.findDetail();
          }
          this.detail.info.praise_status = !this.detail.info.praise_status;
        }
      }
    },

    // 举报
    chengReport(discover_id, type, user_id) {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      } else {
        this.bus.$emit("report", discover_id, type, user_id);
      }
    },

    // 删除评论
    async delete_comment(id) {
      let { code } = await this.$findApi.findDelete({
        id,
      });
      if (code == 200) {
        this.detail.comment = this.detail.comment.filter((item) => {
          item.reply = item.reply.filter((it) => {
            return it.id != id;
          });
          return item.id != id;
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.iconfont {
  font-size: 24px;
}

.iconzan2 {
  color: #e60012;
}

video,
textarea {
  &:focus {
    outline: none;
  }
}

.page {
  > .dkzc-box {
    display: flex;
    flex-direction: row;
    width: 1200px;
    margin: 0 auto;

    > .left {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      width: 890px;

      > .title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        margin-right: 10px;
      }

      > .leftTitle {
        font-size: 24px;
        color: #333333;
        margin-bottom: 10px;
        margin-top: 20px;
      }

      > .personage {
        display: flex;
        flex-direction: row;
        align-items: center;

        > img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          object-fit: cover;
        }

        > .personage-right {
          display: flex;
          flex-direction: column;
          margin-left: 12px;
          width: 100%;

          > .one {
            display: flex;
            flex-direction: row;
            color: #333333;
            font-size: 24px;

            > .position {
              font-size: 13px;
              color: $--themeColor;
              padding: 0 12px;
              box-sizing: border-box;
              height: 25px;
              border: 1px solid $--themeColor;
              border-radius: 6px;
              text-align: center;
              line-height: 24px;
              margin-left: 12px;
            }

            > .button {
              cursor: pointer;
              font-size: 12px;
              background: $--themeColor;
              color: #ffffff;
              width: 66px;
              height: 25px;
              border-radius: 6px;
              text-align: center;
              line-height: 24px;
              margin-left: auto;
            }

            > .button2 {
              cursor: pointer;
              width: 66px;
              height: 25px;
              border: 1px solid #8f8f8f;
              border-radius: 5px;
              color: #8f8f8f;
              text-align: center;
              line-height: 26px;
              margin-left: auto;
              font-size: 12px;
            }
          }

          > .two {
            display: flex;
            flex-direction: row;
            color: #8f8f8f;
            font-size: 16px;
            margin-top: 9px;

            > .site {
              margin-right: 20px;
            }
          }
        }
      }

      > .introduction {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        color: #333333;

        > .subTitle {
          line-height: 1.8;
          font-size: 18px;
          white-space: pre-wrap;
        }

        > .introductionTime {
          display: flex;
          align-items: center;
          flex-direction: row;
          color: #8f8f8f;
          font-size: 14px;
          margin-top: 10px;

          > .timeName {
            margin-right: 10px;
          }
        }
      }

      > .icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #8f8f8f;
        margin-top: 33px;
        cursor: pointer;
        font-size: 24px;

        > .favoritesNumber {
          margin-right: 40px;

          .shoucanghover {
            color: #e60012;
          }
        }

        > .like {
          .zan1 {
            color: #e60012;
          }

          .iconzan2 {
            color: #8f8f8f;
          }
        }

        .report {
          font-size: 22px;
          margin-left: 40px;

          img {
            width: 24px;
            vertical-align: bottom;
          }
        }

        > .share {
          font-size: 16px;
          margin-left: auto;
        }

        > .qq {
          margin: 0 20px;
        }
      }

      > .numberComments {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #333333;
        font-size: 24px;
        margin-top: 30px;
        padding-bottom: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f2f2;

        > .number {
          font-size: 30px;
          color: #e60012;
          margin-right: 5px;
        }
      }
    }

    > .right-group {
      display: flex;
      flex-direction: column;
      width: 294px;
      height: min-content;
      background: #f2f2f2;
      padding: 15px;
      box-sizing: border-box;
      margin-top: 20px;

      > .group {
        margin-bottom: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: row;

        &:not(:last-child) {
          border-bottom: 1px dashed #000;
        }

        &:last-child {
          margin-bottom: 0;
        }

        > .group-left {
          display: flex;
          position: relative;

          > .information {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
            margin-right: 10px;
          }

          > .Play {
            z-index: 8;
            opacity: 0.8;
            position: absolute;
            font-size: 12px;
            color: #ffffff;
            width: 40px;
            height: 18px;
            background: #000000;
            border-radius: 5px;
            text-align: center;
            line-height: 18px;
            left: 57px;
            top: 79px;
          }
        }

        > .right {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: #8f8f8f;
          max-height: 100px;
          overflow: hidden;

          > .title {
            color: #000;
            line-height: 20px;
          }

          > .site {
            color: #000;
            margin-top: 5px;
            height: 55px;
            overflow: hidden;
            line-height: 18px;
            margin-bottom: 10px;

            > .name {
              margin-left: 8px;
            }
          }

          > .time {
            margin-top: auto;
          }
        }
      }
    }
  }
}
</style>
