<template>
  <!-- 分享页面下载appbanner -->
  <div class="wrap">
    <div class="share_banner" @click="appSource" v-show="isShow">
      <img class="banner_img" src="@image/share_banner.png" alt="" />
      <img
        src="@assets/image/share_close.png"
        alt=""
        @click.stop="close_banner"
        class="close"
      />
    </div>
    <!-- 浮窗 -->
    <div class="float_window_container" v-show="float_window_show">
      <div class="float_window">
        点击...可以打开分享页面
        <i class="el-icon-error" @click="float_window_show = false"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: true,
      float_window_show: true,
    };
  },
  methods: {
    // 关闭按钮
    close_banner() {
      this.isShow = false;
    },

    // 点击下载app
    appSource() {
      var ua = window.navigator.userAgent.toLowerCase();
      //微信
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.$alert("点击右上角分享按钮,用外部浏览器打开链接", "提示信息", {
          confirmButtonText: "确定",
        });
      }
      //非微信浏览器
      else {
        if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
          var loadDateTime = new Date();
          window.setTimeout(function () {
            var timeOutDateTime = new Date();
            if (timeOutDateTime - loadDateTime < 5000) {
              window.location =
                "https://itunes.apple.com/cn/app/定考通/id1533002392?mt=8"; //ios下载地址
            } else {
              window.close();
            }
          }, 500);
        } else if (navigator.userAgent.match(/android/i)) {
          try {
            setTimeout(function () {
              window.location =
                "https://a.app.qq.com/o/simple.jsp?pkgname=com.syck.doctorexaminationresit"; //android下载地址
            }, 500);
          } catch (e) {
            e;
          }
        }
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.share_banner {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
.share_banner .banner_img {
  width: 100%;
  height: auto;
}
.share_banner .close {
  position: absolute;
  width: 15px;
  right: 3px;
  top: 3px;
  cursor: pointer;
}
/* 浮窗 */
.float_window_container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;

  > .float_window {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    right: 10px;
    background: $--themeColor;
    padding: 30px 20px;
    color: #fff;
    border-radius: 5px;
    > i {
      cursor: pointer;
    }
  }
}

/* 判断是否显示 */
@media screen and (min-width: 750px) {
  .share_banner {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .share_banner {
    display: block;
  }
  .float_window_container {
    display: block;
  }
}

/* 对话框 */
.el-message-box__wrapper .el-message-box {
  padding: 0;
  width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.el-message-box__wrapper .el-message-box > .el-message-box__header {
  display: block;
}

.el-message-box .el-message-box__message p {
  font-size: 18px;
}

.el-message-box > .el-message-box__btns > .el-button {
  width: 80px;
  height: 35px;
  font-size: 14px;
}
.el-message-box__wrapper .el-message-box > .el-message-box__btns {
  justify-content: center;
  margin-bottom: 10px;
}

.el-message-box > .el-message-box__btns {
  margin-top: 0;
}
</style>
